<template>
  <cropped-image-view
    @close="$emit('close')"
    :src="organization.logo"
    :onSave="save"
  >
  </cropped-image-view>
</template>

<script>
import CroppedImageView from '../components/cropped-image-upload/CroppedImageView.vue'
import { post } from '../api'
export default {
  name: 'LogoDialog',
  components: { CroppedImageView },
  props: ['organization', 'urls', 'onSave'],
  emits: ['close', 'saved'],
  data() {
    return {
      model: {},
      src: undefined,
    }
  },
  async mounted() {},
  methods: {
    async save(base64Image) {
      const result = await post(this.urls.uploadOrganizationLogo, {
        logo: base64Image,
      })
      if (!result.url) {
        throw 'No URL returned from the server'
        return
      }
      this.$emit('saved', {
        logo: result,
      })
      return result
    },
  },
}
</script>
<style scoped></style>
