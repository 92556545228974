import { createApp } from 'vue'
import SendConnectionRequestModal from './SendConnectionRequestModal.vue'

export default class {
  constructor() {
    $('.send-connection-request').on('click', (event) => {
      // $(this).data('memberId') should also work, but for some reason that resolves to undefined.
      // So I'm getting the data attributes from event.target instead.
      let element = $(event.currentTarget)
      const memberId = element.data('memberId')
      const attendeeId = element.data('attendeeId')
      const memberName = element.data('memberName')
      const memberOrganization = element.data('memberOrganization')
      const memberProfilePicture = element.data('memberProfilePicture')

      let component

      if (memberId) {
        component = `#connection-request-${memberId}`
      } else {
        component = `#connection-request-${attendeeId}`
      }

      this.initConnectionRequest(
        memberId,
        attendeeId,
        memberName,
        memberProfilePicture,
        memberOrganization,
        component
      )
    })
  }

  initConnectionRequest(
    memberId,
    attendeeId,
    memberName,
    memberProfilePicture,
    memberOrganization,
    component
  ) {
    let app = undefined

    app = createApp(
      {
        template: '<SendConnectionRequestModal/>',
        components: { SendConnectionRequestModal },
      },
      {
        onClose: () => {
          // Close the modal.
          app.unmount()
          app = undefined
        },
        memberId: memberId,
        attendeeId: attendeeId,
        memberName: memberName,
        memberOrganization: memberOrganization,
        memberProfilePicture: memberProfilePicture,
      }
    )

    app.mount(component)
  }
}
