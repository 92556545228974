<template>
  <modal-container
    @close="
      () => {
        closeButtonPressed()
      }
    "
  >
    <div class="s-modal-body connection-request-modal">
      <div class="s-modal-content" v-if="!success && !error">
        <h2 class="display-1 bold blue">
          Add a message to your connection request
        </h2>
        <p>
          Optional: Send a message along your contact request. This will
          increase your chance of a successful connection.
        </p>

        <textarea
          class="send-connection-request"
          rows="6"
          placeholder="Add a message (optional)"
          v-model="message"
        ></textarea>
      </div>
      <div class="s-modal-content" v-if="success">
        <h2 class="display-1 bold blue">
          Connection request has been sent successfully!
        </h2>
        <p v-if="memberId">
          You will be notified if {{ memberName }} accepts your request.
        </p>
        <p v-if="attendeeId">
          You will be notified if the other person accepts your request.
        </p>
      </div>
      <div class="s-modal-content" v-if="error">
        <h2 class="display-1 bold blue">Failed to send connection request!</h2>
        <p>
          You have already sent a connection request to {{ memberName }}. You
          will be notified if they accept your request.
        </p>
      </div>
      <div class="s-modal-footer">
        <div>
          <div class="personal-profile-badge">
            <div class="profile-picture">
              <div class="avatar">
                <img
                  class="profile-picture"
                  :src="memberProfilePicture"
                  v-if="memberProfilePicture"
                />
                <div class="profile-initials" v-if="!memberProfilePicture">
                  {{ nameToInitials(memberName) }}
                </div>
              </div>
            </div>
            <div class="profile-info">
              <h5 class="margin-0">{{ memberName }}</h5>
              <h5 class="bold blue margin-0">{{ memberOrganization }}</h5>
            </div>
          </div>
        </div>
        <div>
          <a
            :href="`/messaging/?conversation=${conversationId}`"
            v-if="conversationId"
            class="mx-2"
          >
            Go to conversation
          </a>
          <button
            class="button orange medium"
            v-if="conversationId"
            @click="closeButtonPressed()"
          >
            Back to browsing
          </button>
          <button-with-loading-icon
            v-if="!success && !error"
            class="green medium"
            id="send-connection-request"
            @click="sendRequest"
            :loading="loading"
          >
            Send Request<i class="fa fa-paper-plane"></i>
          </button-with-loading-icon>
        </div>
      </div>
    </div>
    <div class="connection-request-modal-background">
      <i class="fas fa-thumbs-up" v-if="success"></i>
      <i class="fas fa-times" v-if="error"></i>
    </div>
  </modal-container>
</template>

<script>
import ModalContainer from '../../components/ModalContainer.vue'
import ButtonWithLoadingIcon from '../../components/ButtonWithLoadingIcon.vue'
import { post } from '../../api'

export default {
  name: 'SendConnectionRequestModal.vue',
  components: { ButtonWithLoadingIcon, ModalContainer },
  props: [
    'memberId',
    'attendeeId',
    'onClose',
    'memberName',
    'memberOrganization',
    'memberProfilePicture',
  ],
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      message: null,
      conversationId: null,
    }
  },
  methods: {
    closeButtonPressed() {
      setTimeout(() => {
        this.onClose()
      }, 200)
    },
    nameToInitials(name) {
      return name
        .split(' ')
        .slice(0, 3)
        .map((word) => word[0])
        .join('')
    },
    async sendRequest() {
      //Send a request to the backend to send a connection request
      this.loading = true
      let url = ''
      if (this.memberId) {
        url = `/messaging/api/conversation/request/`
      } else {
        url = `/messaging/api/conversation/request/`
      }
      try {
        const payload = {
          message: this.message,
          attendee_id: this.attendeeId,
          member_id: this.memberId,
        }
        const response = await post(url, payload)
        if (response.status == 409) {
          this.error = true
        } else {
          this.success = true
        }
        this.conversationId = response.conversation_id
      } catch (error) {
        this.error = true
        console.log(
          `An error occured when starting a new conversation: ${error}`
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
