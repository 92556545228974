<template>
  <img ref="img" :src="src" />
</template>

<script>
import Cropper from 'cropperjs'

export default {
  name: 'ImageCropper',
  props: {
    src: String,
    viewMode: {
      type: Number,
      default: 1,
    },
    aspectRatio: {
      type: Number,
      default: NaN,
    },
    guides: {
      type: Boolean,
      default: false,
    },
    minCropBoxWidth: {
      type: Number,
      default: 50,
    },
    minCropBoxHeight: {
      type: Number,
      default: 50,
    },
    maxCropBoxWidth: {
      type: Number,
      default: 1000,
    },
    maxCropBoxHeight: {
      type: Number,
      default: 1000,
    },
  },
  emits: ['change'],
  data() {
    return {
      cropper: null,
    }
  },
  mounted() {
    this.cropper = new Cropper(this.$refs.img, {
      viewMode: this.viewMode,
      aspectRatio: this.aspectRatio,
      minCropBoxWidth: this.minCropBoxWidth,
      minCropBoxHeight: this.minCropBoxHeight,
      maxCropBoxWidth: this.maxCropBoxWidth,
      maxCropBoxHeight: this.maxCropBoxHeight,
      guides: this.guides,
      autoCropArea: 1,
    })
    this.$refs.img.addEventListener('cropstart', () => {
      this.$emit('change')
    })
  },
}
</script>
