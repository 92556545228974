<template>
  <teleport to="body">
    <transition name="fade">
      <section
        class="s-modal-backdrop"
        @click="$emit('close')"
        @scroll.prevent
        @wheel.prevent
        @touchmove.prevent
      >
        <section class="s-popup-container">
          <article
            class="s-modal-popup"
            @keyup.esc.prevent="$emit('close')"
            @click.stop
          >
            <i
              class="fas fa-times blue close-button"
              @click="$emit('close')"
              style="position: absolute; right: 20px; top: 20px"
            ></i>
            <cropped-image-view
              :src="src"
              :onSave="save"
              :onClose="close"
              :config="config"
            >
            </cropped-image-view>
          </article>
        </section>
      </section>
    </transition>
  </teleport>
</template>

<script>
import CroppedImageView from './CroppedImageView.vue'

export default {
  name: 'CroppedImageModal',
  components: { CroppedImageView: CroppedImageView },
  props: ['src', 'onSave', 'config'],
  emits: ['close'],
  data: () => ({
    error: false,
  }),
  methods: {
    close() {
      this.$emit('close')
    },
    async save(payload) {
      await this.onSave(payload)
      this.$emit('close')
    },
  },
}
</script>

<style scoped></style>
