<template>
  <div class="card mb-5" v-if="!finished">
    <div class="card-body">
      <h2 class="card-title display-5 blue-light bold">
        Show your profile on the attendee list?
      </h2>
      <p class="blue">
        Make your profile visible to help you and others connect before and
        during the event
      </p>
    </div>
    <div class="card-footer row p-4">
      <div class="col-auto mb-4">
        <button @click="accept" class="button orange small">Accept</button>
      </div>
      <div class="col-auto">
        <button @click="decline" class="button orange outline small">
          Decline
        </button>
      </div>
      <div class="col-12 text-muted">
        You can always change this setting in your profile.
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../../api'

export default {
  name: 'EventAttendeeStatusDialog',
  data() {
    return {
      loading: false,
      finished: false,
      model: {
        rememberChoice: true,
      },
    }
  },
  props: {
    event: {
      type: String,
      required: true,
    },
  },
  methods: {
    accept() {
      post(`/account/update-attendee-status/`, {
        show_in_attendee_list: true,
      }).then(() => {
        this.finished = true
        location.reload()
      })
    },
    decline() {
      post(`/account/update-attendee-status/`, {
        show_in_attendee_list: false,
      }).then(() => {
        this.finished = true
      })
    },
  },
}
</script>

<style scoped></style>
